import {ReplayData} from "../util/internalTypes";
import {CircularProgress, Grid, Modal, Paper, Typography} from "@mui/material";
import styles from './replayDataModal.module.css'
import {useEffect, useState} from "react";
import moment from "moment-timezone";

interface Props {
    open?: boolean
    data: ReplayData | undefined
    onClose: () => void
}

interface W3CGameData {
    match: {
        teams: {
            won: boolean
            players: {
                battleTag: string
                mmrGain: number
                oldMmr: number
                currentMmr: number
                won: boolean
            }[]
        }[]
        startTime: string
        endTime: string
        id: number
    }
}

const formatTs = (ts: number) => {
    let h = Math.floor(ts / 3600000)
    let m = Math.floor((ts % 3600000) / 60000)
    let s = Math.floor((ts % 60000) / 1000)
    return [h < 10 ? `0${h}` : h, m < 10 ? `0${m}` : m, s < 10 ? `0${s}` : s].join(':')
}

export default function ReplayDataModal(props: Props) {

    const [w3cData, setW3cData] = useState<W3CGameData | null | undefined>(undefined)

    useEffect(()=>{
        // @ts-ignore
        if(props.data && w3cData && props.data.meta.gamename.match(/w3c-[0-9]+-([A-z0-9\-_]+)-[0-9]+/)?.[1] !== w3cData?.match?.['original-ongoing-match-id']) {
            setW3cData(null)
        }
        else if(props.data && !w3cData) {
            console.log('Fetching w3c gamedata')
            fetch(`https://website-backend.w3champions.com/api/matches/by-ongoing-match-id/${props.data.meta.gamename.match(/w3c-[0-9]+-([A-z0-9\-_]+)-[0-9]+/)?.[1]}`).then(r=>r.json()).then((r)=>{
                console.log('Received w3c gameData', r)
                if(r?.match) setW3cData(r)
                else setW3cData(null)
            }).catch((e)=>{
                console.log('Failed to fetch W3C gameData.')
                setW3cData(null)
            })
        }
    }, [w3cData, props.data])

    const getPlayerMMRFromW3cData = (playerId: number) => {
        if(!w3cData) return null
        else {
            const player = [...w3cData.match.teams[1].players, ...w3cData.match.teams[0].players][playerId - 1]
            return {
                change: player.mmrGain,
                old: player.oldMmr,
                won: player.won
            }
        }
    }

    const getFormattedMmrData = (playerId: number) => {
        const data = getPlayerMMRFromW3cData(playerId)
        if(!data) return ''
        else return <span style={{color: 'rgb(200,200,200)'}}>
            ({data.old} <span style={{color: data.won ? 'green' : 'red'}}>{data.change >= 0 ? `+${data.change}` : `${data.change}`}</span>)
        </span>
    }

    const formatW3cGameDuration = () => {
        if(!w3cData) return null
        const from = moment(w3cData.match.startTime)
        const to = moment(w3cData.match.endTime)
        const s = to.diff(from, 's')
        return from.format('DD/MM/YYYY HH:mm')+' - '+to.format('HH:mm UTC')+` (Duration: ${s > 3600 ? Math.floor(s/3600) : '00'}:${s > 60 ? Math.floor((s%3600)/60) : '00'}:${s%60})`
    }

    return <Modal onClose={props.onClose} className={styles.modalRoot} open={props.open === undefined ? !!props.data : props.open}>
        {
            props.data ?
            <Paper className={styles.paperRoot}>
                <div><Typography variant={'h5'} fontWeight={'bold'}>Decoded Replay Data</Typography></div>
                <Grid container direction={'row'} className={styles.gridRoot} spacing={2}>
                    <Grid container item xs={6} xl={4} direction={'column'}>
                        <Grid item xs={6}>
                            <div><Typography variant={'h6'} fontWeight={'bold'}>Players</Typography></div>
                            <Grid container direction={'row'}>
                                <Grid item xs={6}>
                                    {Object.values(props.data.players).slice(1, 1+Math.floor(Object.values(props.data.players).length/2)).map((x, i)=><div style={{textAlign:'left', fontWeight: 'bold', color:x.color === '#540081' ? '#8f3fbd' : x.color}}>
                                        {x.timeInGame && <span style={{color: 'white'}}>[{formatTs(x.timeInGame)}]</span>} <a target='_blank' href={`https://w3champions.com/player/${encodeURIComponent(x.battleTag)}`}><abbr title={`Rounded APM: ${x?.apm || 'N/A'}`}>{x.battleTag}</abbr></a> {getFormattedMmrData(i+1)}
                                    </div>)}
                                </Grid>
                                <Grid item xs={6}>
                                    {Object.values(props.data.players).slice(1+Math.floor(Object.values(props.data.players).length/2), 1+2*Math.floor(Object.values(props.data.players).length/2)).map((x, i)=><div style={{textAlign:'right', fontWeight: 'bold', color: x.color}}>
                                        {/*@ts-ignore*/}
                                        {getFormattedMmrData(i+1+Math.floor(Object.values(props.data.players).length/2))} <a target='_blank' href={`https://w3champions.com/player/${encodeURIComponent(x.battleTag)}`}><abbr title={`Rounded APM: ${x?.apm || 'N/A'}`}>{x.battleTag}</abbr></a> {x.timeInGame && <span style={{color: 'white'}}>[{formatTs(x.timeInGame)}]</span>}
                                    </div>)}
                                </Grid>
                                { props.data.players[props.data.ownerPlayerId]
                                    &&
                                    <div><Typography variant={'subtitle2'} fontWeight={'bold'}>
                                        Replay Owner: <span style={{color: props.data.players[props.data.ownerPlayerId].color.replace('#540081', '#8f3fbd')}}>{props.data.players[props.data.ownerPlayerId].battleTag}</span>
                                    </Typography></div>
                                }
                            </Grid>
                            <div><Typography variant={'h6'} fontWeight={'bold'}>W3C GameData</Typography></div>
                            {w3cData === undefined ? <div>
                                <CircularProgress color={'info'}/>
                            </div> : ( w3cData ? <div>
                                <div>{formatW3cGameDuration()}</div>
                                <div>Winning Team: {
                                    w3cData.match.teams[0].won ? 'East' : 'West'
                                }</div>
                                <div>
                                    <a style={{textDecoration: 'underline'}} target='_blank' href={`https://w3champions.com/match/${w3cData.match.id}`}>Go to match</a>
                                </div>
                            </div> : <div>Couldn't find match on W3C.</div>)}
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} xl={8}>
                        <div><Typography variant={'h6'} fontWeight={'bold'}>Chat</Typography></div>
                        <div style={{overflowY: 'auto', maxHeight: 'calc(90vh - 10rem)', textAlign: 'left'}}>
                            {props.data.chat.map(x=><div>
                                {/*@ts-ignore*/}
                                ({formatTs(x.ts)}) <span style={{color: props.data.players[x.playerId].color.replace('#540081', '#8f3fbd')}}>{props.data.players[x.playerId].battleTag}</span> [{x.mode}]: <span className={styles.chatMsg}>{x.message}</span>
                            </div>)}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            : <></>
        }
    </Modal>
}